.intro-page {
  padding-top: 0;
  padding-bottom: 0;
  & > section {
    padding-top: 100px;
    height: 100vh;
    min-height: 620px;
    overflow: hidden;
  }
}

.intro-home {
  background-image: url('../img/home-bg.jpg');
  background-position: bottom;
  h1 {
    font-size: 60px;
    position: absolute;
    bottom: 190px;
    font-weight: 300;
    line-height: 1.2;
  }
}

.page-activities {
  article {
    &.__giant {
      margin-bottom: 0px;
    }
  }

  .show-more {
    width: 80px;
    display: block;
    margin: auto;
    float: none;
  }
}
.intro-activities {
  .img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  article {

    h1 {
      line-height: 28px;
      margin-bottom: 0;
    }
  }
  @media #{$desktop} {
    article {
      margin-bottom: $unit;

    }
  }
}

.page-technology {
  @media #{$desktop} {
    article {

    }
  }
}
.technology-big{
  .technology-platforms{
    font-size: 14px;
    color: $text-light;
  }
  .technology-lead{
    margin-top: 25px;
    margin-bottom: 28px;
    @media #{$tablet} {
      max-height: 10.4em;
    }
    overflow: hidden;
  }
  .technology-lead-smallheight{
    @media #{$tablet} {
      min-height: 6.5em;
    }
  }
  .img {
    background-size: cover;
    display: block;
    float: left;
    margin-right: 20px;
    height: 100%;
    &.reverse {
      float: right;
      margin-right: 0;
      margin-left: 20px;
    }
  }
  @media #{$desktop} {
    .img {
      background-size: cover;
      display: block;
      float: left;
      margin-right: 72px;
      height: 100%;
      &.reverse {
        float: right;
        margin-right: 0;
        margin-left: 72px;
      }
    }
  }
  @media #{$desktop} {
    .content{
      padding: 60px;
    }
  }
}
.intro-news {
  @media #{$desktop} {
    article {
      margin-bottom: 40px;
      .img {
        // float: left;
        height: 100%;
        width: 30%;
      }
    }

    .button {
      float: right;
    }
  }
}

.main-article {
  // article {
  //   h1 {
  //     margin-bottom: 0;
  //   }
  // }
  .title {
    display: inline-block;
    height: 60px;
  }

}
.source {
  position: absolute;
  color: $brand-color-red;
  bottom: $unit / 2;
  right: $unit / 2 ;
  text-decoration: underline;
}

.main-database {

  .highlight {
    font-size: 24px;
  }

  .suggestions {
    font-size: 14px;
    color: $text-gray;
    a {
      // font-size: 18px;
      // color: $brand-color-darker;
      text-decoration: none;
    }

    ul {
      margin-bottom: 40px;
    }
  }
}

.page-support {
  label {
    color: $brand-color-darker;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
  }

  input[type="radio"], input[type="checkbox"] {
    margin-right: 20px;
  }
  input[type="text"] {
    width: 134px;
    background-color: #F5F5F5;
  }
}
.page-project {
  @media #{$desktop} {
    article.content {
      padding: 40px 40px 40px 40px;
    }
  }
  article > h1 {
    margin-top: 40px;
  }
}
@media #{$desktop}{
.bigpd {
      padding: 40px 40px 40px 40px;
  }
}
.support-content{
  p{
    font-family: 'PT Serif', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  h2, h3, h4, h5{
    color: $text-dark;
  }
  h5{
    font-size: 18px;
    font-weight: 400;
  }
  h4{
    font-size: 24px;
    font-weight: 700;
  }
  h3{
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 70px;
  }
  h2{
    font-size: 18px;
    font-weight: 700;
    margin-top: 70px;
  }
}
.contact{
  padding:0;
  .contact-img-small{
    width: 120px;
    height: 120px;
    background-size: cover;
    margin-right: 20px;
  }
  .contact-content{
    padding:20px;
  }
  .contact-small h1{
        margin-bottom: 0;
  }
}
.logos img{
  width: 180px;
  margin-left: auto;
  margin-right: auto;
}
@media #{$tablet} {
  .logos img{
    width: 190px;
  }
  .w20{
    width: 33.3%;
  }
}
@media #{$desktop} {
  .logos img{
    width: 200px;
  }
  .w20{
    width: 20%;
  }
}
.page-search{
  .search-content{
    padding: 0 30px;
  }
  h1.main{
    min-height: 55px;
  }
  h1.section-title-light{
    margin-top: 0px;
    margin-bottom: 20px;
  }
  form{
    margin-bottom: 60px;
  }
  .label--toggle{
    color: $text-dark;
    font-size: 16px;
    span{
      font-size: 14px;
      color: $text-dark-gray;
      margin-left: 6px;
    }
  }
  article.__small{
    h1{
      font-weight: 700;
      text-decoration: underline;
    }
    a{
      text-decoration: underline;
    }
    p{
      font-family: 'PT Serif', serif;
    }
    .content{
      padding: 30px;
    }
  }
  h5{
    color: $text-dark-gray;
    margin-top: 0px;
    span{
      color: #00BBFF;
    }
  }
}
.page-terms_of_use{
  p{
    font-size: 16px;
    font-family: 'PT Serif', serif;
  }
  .section-title-light{
    margin-top: 5px;
    margin-bottom: 35px;
  }
}
