
.logo-text {
  color: $brand-color-red;
  width: 100px;
  width: 100px;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.menu-container{
  position: fixed;
  margin-top: 41px;
  overflow: auto;
  height: 100%;
  display: none;
}
.menu-container.active{
  display: block;
}
header {
  .collapse {
    display: block;
    background-color: white;
    padding: 20px 0 60px;
    a {
      float: none;
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 20px 0px;
      font-size: 24px;
      font-weight: 600;
    }
  }
  @media #{$tablet} {
    .menu-container{
      display: block;
      width: 100%;
      position: none;
      margin-top: 0;
      overflow: none;
      height: auto;

    }
    .collapse{
    border-top: 1px solid $border-color;
    text-align: center;
    margin-top: 79px;
    margin-right: 0px;
    position: relative;
    top: auto;
    padding:0;
    a {
      float: none;
      display: inline-block;
      flex-grow: 1;
      width: auto;
      font-size: 16px;
      padding: 0px 10px;
      font-weight: 400;
      white-space: nowrap;
    }
    display: block;
    display: flex;
    //   float: right;
    clear: both;
    //   width: 100%;
    //   position: relative;;
    //   left: 0px;
    //   top: $unit;
    }
  }
  @media #{$desktop} {
    .collapse{
      display: block;
      float: right;
      width: auto;
      clear: none;
      position: static;
      margin-right: 141px;
    }
  }
  $height: $unit;
  background-color: $white;
  border-bottom: 1px solid $border-color;
  height: $height;
  // overflow: hidden;
  width: 100%;
  position: fixed;
  z-index: 100;
  .menu-collapsed{
    float:right;
    width: $unit / 2;
    text-align: center;
  }
  .logo {
    border: 0;
    display: inline-block;
    position: relative;
    z-index: 1;
    height: $height;
    width: auto;

    @media #{$tablet}{
      &:after {
        color: $brand-color-red;
        content: attr(data-content);
        width: 200px;
        display: block;
        left: $unit * 2.5;
        position: absolute;
        top: 42px;
        font-weight: bold;
      }
    }
  }

  nav {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    font-size: 16px;
    a {
      color: $text-dark;
      display: block;
      float: left;
      font-size: 16px;
      height: $height;
      line-height: $height;
      padding: 0 10px;
      text-decoration:none;
      font-weight: 400;
      position: relative;
    }
    .active:before{
      content:"";
      display: block;
      position: absolute;
      bottom:0px;
      width: 100%;

      background-color: $brand-color-darker;
      margin-left: -10px;
    }
    .highlight{
      color: $brand-color-red;
    }
    .extra-menu {

      display: block;
      text-align: center;
      position: relative;
      z-index: 200;
      @media #{$tablet} {
        display: inline-block;
        text-align: left;
      }
      a {
        line-height: 38px;
        height: auto;
      }
      height: 40px ;
      // margin-top: 20px;
      // margin-right: 30px;
    }

    .alter-bg1 {
      background-color: $brand-color-darker;
      color: $text-white;
      font-weight: 400;
      font-size: 14px;
    }
    .alter-bg2 {
      background-color: $bg-color;;
      color: $text-white;
      border-bottom: 1px solid #dbdbdb;
      color: $text-dark;
      font-weight: 400;
      font-size: 14px;
    }
    .support {
      margin-left: $unit * .75;
      padding: 0;
      text-align: center;
      width: $unit * 3.5;
    }
    .language-selector {
      padding: 0;
      text-align: center;
      width: $unit * 2;
    }
  }
  $height: $unit * 2;
  @media #{$tablet} {
    height: $height * 1.5;
    .menu-collapsed{
      display: none;
    }
    .logo {
      width: auto;
      display: inline-block;
      padding: 5px;
      padding-left: 20px;
      height: $height - 20;
    }

    .collapse a {
      height: $height / 2;
      line-height: $height / 2;
    }
    nav a {
      height: $height / 2;
      line-height: $height / 2;
    }
    nav{
      .active:before{
        height:2px
      }
    }
  }
  @media #{$desktop} {
    height: $height;
    .collapse{
      margin-top: -1px;
      margin-right: 20px;
    }
    nav{

      .active:before{
        height:6px
      }
    }
    .collapse a {
      height: $height;
      line-height: $height;
    }
  }
}
.subscribe-success{
  padding-top: 10px;
  svg{
    width: 55px;
    float: left;
    margin-top: 10px;
  }
  p{
    font-size: 30px;
    margin-top: 18px;
    margin-left: 80px;
    font-weight: 400;
    line-height: 36px;
  }

}
