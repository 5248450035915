@import 'reset';
@import 'variables';

@import 'grid';
@import 'buttons';
@import 'header';
@import 'footer';
@import 'pages';

.no-scroll {
  overflow: hidden;
}

.overlay {
  position: fixed;
  background-color: rgba(0,0,0,.4);
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 20px;
  padding-top: 40px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  overflow: auto;
  display: none;

  &--visible {
    display: block;
  }

  &__popup {
    height: auto;
    width: 100%;
    max-width: 772px;
    margin: auto;
    background-color: #ffffff;
    text-align: center;
  }
}

.popup {
  position: relative;
  &__padding {
    padding: 30px;
  }
  &__title {
    margin: 10px 0 15px;
    font-size: 30px;
    font-weight: 300;
  }
  &__subtitle {
    color: #999999;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  &__paragraph {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin: 30px 0;
  }
  &__button {
    display: block;
    max-width: 230px;
    margin: auto;
  }
  &__close {
    cursor: pointer;
    font-family: 'Material Icons';
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -30px;
  }
}

.database_desc {
  height: 10.2rem;
  overflow: hidden;
}
.flex-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .col {
    padding-bottom: 20px !important;
    .article-content {
      height: auto !important;
      margin-bottom: 0 !important;
      padding-bottom: 0;
    }
    article {
      height: 100%;
    }
    flex: 1;
    padding-bottom: 20px;
  }
}
.click_block {
  text-decoration: none;
}

#custom-donation__input {
  top: -10px;
  position: relative;
}

#custom-scrollbar {
  position: fixed;
  background-color: $brand-color-red;
  // top: 0;
  z-index: 1000;
  right: 0;
  height: 120px;
  width: 15px;
  display: none;
  @media #{$desktop} {
    display: block;
  }
}
iframe {
  background-color: $white;
}
.mbottom{
  margin-bottom: 20px;
}
a {
  color: $brand-color
  // color: inherit;
  // text-decoration: none;

}
.link, a.link {
  a & {
    color: $brand-color-red;
  }
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
display: inline-block;
  font-size: 14px;
  text-decoration: none;
}
p{

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;

  margin-bottom: 30px;
}
.bold{
  font-weight: 700;
}
.halfpd{
  margin: 0 -10px;
   .col{
    padding: 0 10px;
  }
}
ul li {
  list-style: disc;
}
ol li {
  list-style:decimal;
}

.nonepdbottom{
  padding-bottom: 0;
}
.nonemgbottom{
  margin-bottom: 0 !important;
}
.contact-small h1 {
    margin-bottom: 0;
    font-weight: 400;
}
.contact-post{
  font-size: 14px;
  font-weight: 400;
  color: $text-gray;
}
.filter-menu {
  margin-bottom: 40px;
  li{
    list-style: none;
    float: left;
    margin-right: 20px;
    a{
      color: $text-dark;
      font-size: 20px;
    }
    a.active{
      color: $brand-color-red;
    }
  }
}
.input-wrapper {
  & ~ input[type="submit"] {
    width: 100%;
  }
  input {
    margin-bottom: 0;
  }
  @media #{$tablet} {
    input {
      float: left;
      width: 100%;
    }
    margin-right: $unit * 3;
    & ~ input[type="submit"] {
      width: $unit * 3;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

p {


    margin-bottom: 20px;
  strong {
    color: $brand-color-darker;
  }
  &.__big,  &.__big a{
    font-size: 24px;
  }
}
h1.section-title {
  /*display: inline-block;
  margin-right: $unit * .25;
  font-weight: 700;*/
}

h1 {
  color: $text-dark;
  font-size: 30px;
  margin-bottom: 45px;
  font-weight: 600;
  position: relative;
  span{
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-top: -2px;
    color: $text-gray;
    line-height: 1.3;
    @media #{$tablet} {
      // margin-right: 180px;
    }
  }
  a{
    font-size: inherit;
    color: inherit;
    font-weight: 600;
  }
  a.home{
    color: $brand-color-red;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    float: right;
  }
  &.section-title{
    font-size: 24px;
    margin-bottom: 20px;
    display: block;
  }
  &.section-title-light{
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 15px;
  }
}

.project-content{
  .article-info {
    margin-top: 26px;
    margin-bottom: 20px;
  }
}
.article-info {
  margin-top: -20px;
  margin-bottom: 12px;
  height: 30px;
  .tag {
    background-color: $brand-color;
    padding: 0 20px;
  }
}

.project-header {
  background-size: cover;
  background-position: center;
  position: relative;
  height: 480px;
  height: 400px;
  margin-top: -20px;
  margin-bottom: -180px;
  margin-bottom: -70px;
  box-sizing: border-box;

  h1 {
    margin-top: 0px;
    color: #fff;
    font-size: 48px;
    font-weight: bold;
    position: absolute;
    bottom: 62px;
    font-weight: 600;
    width: 90%;
    line-height: 52px;
  }
  @media #{$tablet} {
    h1{
      width: 65%;
    }
  }
  @media #{$desktop} {
    h1{
      width: 50%;
    }
  }
}
@media #{$tablet} {
.multiple-column-2{
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 25px;
  -moz-column-gap: 25px;
  column-gap: 25px;
}
}
@media #{$desktop} {
  .multiple-column-2{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
  }
}
address {
  color: $brand-color-darker;
  font-size: 18px;
}
.article-title {
  display: block;
  @media #{$tablet} {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
  font-size: 18px;
  line-height: 21px;
  &.__big {
    font-size: 24px;
  }
  &.__normal {
    font-size: 18px;
  }
}
article{
  color: $text-dark;
  &[onclick] {
  cursor: pointer;
  }
  position: relative;
  h1 {
    @extend .article-title;
    margin-bottom: 0;
    font-weight: 600;
    margin-bottom: 28px;
  }

  h2 {
    color: $brand-color;
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }
}

.tag-container{
      margin: 7px 0;
  a{
    color: $text-dark;
    text-decoration: underline;
    font-size: 16px;
  }
}
.tag {
  background-color: $brand-color-red;
  color: $text-white;
  border-radius: 4px;
  border-radius: 15px;
  font-size: 10px;
  display: inline-block;
  line-height: 21px;
  padding: 0 10px;
  margin-right: 10px;
  text-transform: uppercase;
}

.content {
  &.alter {
    background-color: $brand-color-darker;
    color: $text-white;
    a {
      color: $text-white;
      text-decoration: none;
      font-size: 16px;
    }
  }
}

article.__small {
  .content {
    padding: 20px;
  }
  h1{
    font-weight: 400;
    white-space: normal;
  }
}
article.__bg {
  background-size: cover;
  background-position: center;
  color: $text-white;
p{
  line-height: 1.3;
  margin-bottom: 31px;
}
  .container{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  h1 {
    color: $text-white;
  }
  .button {
    background-color: $brand-color;
    background-color: transparent;
    border: 1px solid white;
    text-transform: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 600;
  }
}
article.__giant {
  h1 {
    font-size: 42px;
  }
  p {
    font-size: 24px;
  }
}
.container-slim article.__big {
  h1 {

    font-weight: 700;

  }
}
article.__big {
  h1 {
    font-size: 24px;
    line-height: 28px;
    // max-height: 26px;
    // overflow: hidden;
    margin-bottom: 25px;
    font-weight: 600;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  time{
    margin-top: -20px;
  }
  p {
    font-size: 16px;

  }
}
.contact-img-small{
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  float: left;
  margin-right: 20px;
}
.label-highlight{
  padding: 7px 15px;
  background-color: $brand-color-darker;
  display: block;
  float: left;
  margin-right: .25*$unit;
  border-radius: 15px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 1rem;

}
.highlight-light{
  background-color: #e6e6e6;
  font-weight: 400;
  color: $text-dark-gray;
  margin-right: 10px;
}
.label-info-container{
  margin-top: 32px;

}
.label-info{
  margin-top: 10px;
  &:after{
    content: '';
    clear: both;
    display: block;
  }
  line-height: 2.75rem;
}
.article-info .label{
  padding: 7px 15px;
  background-color: transparent;
  display: block;
  float: left;
  margin-right: .25*$unit;
  border-radius: 15px;
  height: 30px;
  box-sizing: border-box;
  color: $text-light;
  font-size: 14px;
}
.page-project{
  p{
    font-family: 'PT Serif', serif !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    color: $text-dark-gray !important;
    b{
      font-weight: 700;
    }
  }
}
.page-project article > h1.halfmargin, .page-support h2.halfmargin{
  margin: 20px 0 15px 0;
}
.onepixel{
  width: 1px;
  height: 1px;
}
.contact-content{
  font-size: 18px;
  color: $text-dark;
}
.contact-content strong{
  font-weight: 600;
}
h5{
  color: $text-gray;
  margin-top: 20px;
  font-size: 14px;
}
.story{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 32px;

  .flex:last-child {
    .dotted {
      display: none;
    }
  }

  .mark:before{
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    border: 1px solid white;
    border-radius: 20px;
    background-clip: content-box;
    padding: 3px;
    background-color: $text-dark;
  }
  .active.mark:after{
    display: block;
    width: 0px;
    height: 0px;
    margin-left: 1px;
    margin-top: 10px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $brand-color-darker;
    content: '';
    // background-image: url(http://karcos.hu/arrow-up.svg);
    // background-repeat: no-repeat;
    // display: block;
    // width: 13px;
    // height: 7px;
    // position: absolute;
    // top: 23px;
    // margin-left: 2px;
  }
  .active.mark:before{
    border: 1px solid black;
    background-color: $brand-color-red;
  }
  .mark{
    width: 18px;
    margin-right: 20px;
    float: left;
    margin-top: 3px;
    position: relative;

    .dotted{
      width: 1px;

      background-image: linear-gradient(to bottom, $brand-color-darker 25%, rgba(255, 255, 255, 0) 25%);
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;

      margin-left: 8px;
      margin-top: 8px;
      margin-bottom: 8px;
      position: absolute;
      bottom: 0px;
      top: 18px;
    }
  }
  .story-content{
    min-height: 158px;
    h2{
      font-size: 18px;
      font-weight: 700;
      color: $text-dark;
    }
    .date{
      font-size: 14px;
      font-weight: 400;
      color: $brand-color-red;
      margin-bottom: 10px;
      margin-top: 4px;
    }
    .description{
      font-family: 'PT Serif', serif;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.story .flex:last-child .dotted{
    border-left: 0;
    min-height: 1px;
}

.flex:after{
  content: '';
  display: block;
  width: 1px;
  clear: both;
}
.flex{
  display: flex;
}
hr{
  background-color: #dbdbdb;
    border: 0;
    height: 1px;
    margin: 20px 0;
}
h1.main{
  // min-height: 77px;
  margin-bottom: 25px;
}
.trough-white{
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 30px;
  padding-top: 30px;
}
.trough-dark{
  width: 100%;
  margin-top: -20px;
  background-color: $brand-color-darker;
  border-bottom: 0;
  height: 400px;
  margin-top: -20px;
  h1{
    padding-top: 100px;
    color: white;
    font-weight: 300;
    font-size: 40px;
  }
}
.icon-container{
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0;
  .col {
    display: inline-block;
    float: none;
    font-size: 1rem;
    vertical-align: top;
  }
}
.icon-box{
  width: 50px;
  height: 50px;
  background-color: $brand-color-darker;
  float: left;
  font-family: 'Material Icons';
  font-size: 24px;
  color: white;
  text-align: center;
  line-height: 51px;
  margin-top: 25px;
}
.icon-content{
  padding-left: 70px;
  margin-top: 25px;
  margin-bottom: 25px;
  h1{
    padding-top: 0;
    margin-bottom: 10px;
    margin-top: -6px;
    font-size: 24px;
  }
}
.nfo{
  background-color: #FCEE21;
  z-index: 999999;
  position: fixed;
  width: 100%;
  display: none;
  .col h1.section-title-light{
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 8px;
  }
  a.light{
    font-size: 14px;
    opacity: 0.5;
    text-decoration: underline;
    color: $text-dark;
  }
  a.button{
    border-radius: 20px;
  }
  p{
    font-size: 14px;
    color: $text-dark;
    width: 54%;
    float: right;
    padding-top: 20px;
    display: none;
  }
}
.align-right{
  text-align: right;
}
.news-content{
  .img{
    background-position: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.levitate-mtop{
  margin-top: -32px;
  margin-bottom: 15px;
  font-size: 14px;
  color: $text-dark;
}
.levitate{
  margin-bottom: 15px;
  font-size: 14px;
  color: $text-dark;
}
.levitate-absolute{
  position: absolute;
  top: -32px;
  font-size: 14px;
  color: $text-dark;
}
.levitate-absolute_mtop{
  margin-top: 80px;
  position: relative;
}
/*
h2 {
  color: $text-dark;
  font-size: 24px;
}
h3 {
  color: $brand-color;
  font-size: 16px;
}
*/
















.gradient-bl-left {
    background: rgba(0,0,0,0.7);
    background: -moz-linear-gradient(left, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 50%);
    background: -webkit-gradient(left top, right top, color-stop(10%, rgba(0,0,0,0.7)), color-stop(50%, rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 50%);
    background: -o-linear-gradient(left, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 50%);
    background: -ms-linear-gradient(left, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 50%);
    background: linear-gradient(to right, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
    height: 100%;
}


body, html {
  min-height: 100%;
  width: 100%;
}

body {
  background-color: $bg-color;
  color: $text-dark-gray;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;

}
img {
  display: block;
}


ul,
ol {
  @extend %clear;
  margin-left: 20px;
}

// TODO: rárakni másik stylere
// ol{
//   padding-left: 60px;
//   position: relative;
//   margin-bottom: 20px;
//   li {
//     margin-bottom: 10px;
//     &:before {
//       content: attr(data-year);
//       position: absolute;
//       left: 0px;
//     }
//   }
// }


.ad {
  margin-top: 40px;
  text-align: center;
  img {

  }
}


// TODO: rárakni másik stylre
// ul li {
//   float: left;
//   margin-right: 20px;
// }

section {
  @extend %clear;
  box-sizing: border-box;
  padding: 20px 0;
}

article.__big {
  font-size: 16px;



}


input[type='text'], textarea {
  background-color: $white;
  border: 0;
  border-bottom: 1px solid $border-color;
  padding: 0 20px;
  height: $unit;
  width: 100%;
  line-height: $unit;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

textarea {
  height: 280px;
}

time {
  color: $text-light;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-top: -23px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}






.clear {
  @extend %clear;
}


input, textarea {
    margin-bottom: 10px;
}
.donation{
  padding: 30px;
}

.no-border {
  border: 0 !important;
}

.page-home {
  padding-bottom: 0;
}

.sub {
  background-color: white;
  display: none;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 30px 20px;
  border-bottom: 1px solid #dbdbdb;
  input[type="submit"] {
  }
  article:last-child{
    margin-bottom: 0;
  }
  span.radio{
    width: 6px;
    height: 6px;
    border-radius: 20px;
    border: 1px solid #B3B3B3;
    display: block;
    float: left;
    background-clip: content-box;
    background-color: transparent;
    padding: 6px;
    margin-right: 12px;
    margin-top: 5px;
  }
  label span.radio:last-of-type{
    margin-top: 9px;
  }
  span.radio.active{
    background-color: $brand-color-red;
  }
  span.checkbox{
    width: 6px;
    height: 6px;
    border: 1px solid #B3B3B3;
    display: block;
    float: left;
    padding: 6px;
    margin-right: 12px;
    margin-top: 5px;
  }
}
.tab__content--active {
  display: block;
}
.tab__nav-item {
  list-style: none;
  display: inline;
}
li.tab__nav-item--active a{
  color: #00BBFF !important;
}
@media #{$desktop} {
  .sub {
    padding: 30px 40px;
  }
}
.sub form label:last-of-type{
  margin-bottom: 20px;
}
.serif{
  font-family: 'PT Serif', serif;
}
.serif-big{
  font-size: 18px;
}

article {
  @extend %clear;
  background-color: $white;
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;
  box-sizing: border-box;
  overflow: hidden;


}
.page-news .article-content{
  height: 161px;
  overflow: hidden;
}
.article-content{
  @media #{$tablet} {
    height: 144px;
  }
  overflow: hidden;
  margin-bottom: 40px;
}
.article-content-normal{
  height: 178px;
  overflow: hidden;
  margin-bottom: 40px;
}
.article-content-nopics{
  height: 183px;
  overflow: hidden;
}
.article-content-big{
  height: 188px;
  overflow: hidden;
}
.cta{
  margin-top: 40px;
  article{
    background-color: $brand-color-darker;
    color: white;
    h1{
      color: white;
    }
    p{
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      line-height: 1.2;
      color: #63717A;
    }
    .button{
      background-color: #00BBFF;
      margin: 0;
    }
  }
}
.img{
  background-size: cover;
  background-position: center;
}
article.vertical {
  .img {
    float: none;
    height: auto;
    width: 100%;
    padding-top: 58%;
  }
}

article .people-card,
.people-card {
  margin-bottom: 20px;
  h1 {
    color: $brand-color-darker;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .img{
    height: 60px;
    border-radius: 10px;
    float: left;
    margin-right: 20px;
  }
  .info {
    color: $text-gray;
  }
}
.bottom{
  position: absolute;
  bottom: 0px;
}

.label {
  padding: 0;
  position: relative;
  &:not(:last-child){
    margin-bottom: 20px;
  }
  &--toggle {
    color: $white;
    display: block;
    padding-left: 30px;
    line-height: 20px;
  }
  &--search {
    &:before {
      position: absolute;
      color: $brand-color-red;
      line-height: 2.5*$unit;
      padding-left: 1*$unit;
      content: 'search';
    }
    .label__input {
      padding-left: 2.5*$unit;
    }
  }
  &--theme-white {
    &:before {
      color: $white;
    }
    .label__input{
      background-color: rgba($white, .25);
      color: $white;
    }
  }
  &__input {
    background-color: mix(red, $white, 50);

    color: $brand-color-red;
    border: 0;
    line-height: 20;
    padding: 0*$unit 1*$unit;
    width: 100%;
    display: block;



    &--checkbox {
      left: -9999px;
      margin: 0;
      position: absolute;
      &:focus {
        outline: none;
      }
      &:focus ~ .label__input-helper, &:active ~ .label__input-helper {
        border-width: 2px;
        padding: 3px;
      }
      & ~ .label__input-helper {
        box-sizing: border-box;
        border: 1px solid $white;
        background-color: transparent;
        background-clip: content-box;
        border: 1px solid #B3B3B3;
        width: 20px;
        height: 20px;
        padding: 4px;
        content: '';
        float: left;
        margin-left: -30px;
        // position: relative;
        // left: 9999px;
        display: block;
      }
      &:checked ~ .label__input-helper {
        background-color: $brand-color-red;
      }
    }
    &--radio {
      @extend .label__input--checkbox;
      & ~ .label__input-helper {
        border-radius: 50%;
      }
    }
    &:focus {
      outline: none;
    }
  }
}
@media #{$tablet} {
  .w65{
    width: 100%;
  }
  .w35{
    width: 100%;
  }
  .w66{
    width: 100%;
  }
  .w34{
    width: 100%;
  }
}
@media #{$lg-tablet} {
  .w65{
    width: 65%;
  }
  .w35{
    width: 35%;
  }
  .w66{
    width: 66%;
  }
  .w34{
    width: 34%;
  }
  .donation{
    padding: 20px 11px;
    ul{
      li{
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}
@media #{$desktop} {
  .donation{
    padding: 30px 30px;
    ul{
      li{
        margin-right: 20px;
        margin-left: 0px;
      }
    }
  }
}
.warning{
  font-size: 11px;
  margin-top: -6px;
  display: block;
  color: $brand-color-red;
}
.validaton-msg {
  display: none;
}

.label--error {
  input, textarea {
    color: $brand-color-red;
  }
  .validaton-msg {
    display: block;
  }
}
