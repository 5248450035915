// $mobile: '(max-width: 699px)';
$environment: 'development';

$mobile: '(max-width: 699px)';
$tablet: '(min-width: 700px)';
$lg-tablet: '(min-width: 993px)';
$desktop: '(min-width: 1200px)';

$white: #fff;
$bg-color: #f5f5f5;
$border-color: #dbdbdb;
$brand-color: #0bf;
$brand-color-red: #f05351;
$brand-color-darker: #081f32;

$text-base: #666;
$text-dark: #081f32;
$text-white: #fff;
$text-light: #b3b3b3;
$text-gray: #999;
$text-dark-gray: #666666;

$unit: 40px;
