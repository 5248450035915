footer {
  overflow: hidden;
  padding-top: 0px;
  background-color: $brand-color-darker;
  .container{
    padding: 60px 40px 40px;
  }
  nav a{
    display: block;
    color: white;
    line-height: 24px;
    font-size: 16px;
    text-decoration: none;;
    font-weight: 400;
  }
  input[type='submit']{
    background-color: $brand-color-red;
  }
  ul li {
    list-style: none;
  }
  h1{
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  p, label{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: mix($brand-color-darker, $white, 50%);
  }
  #signup {
    &__consent-label {
      align-items: center;
      color: mix($brand-color-darker, $white, 25%);
      display: flex;
      margin-bottom: 20px;
    }
    &__consent-input {
      margin-bottom: 2px;
      margin-right: 10px;
    }
    &__submit:disabled {
      opacity: .5;
    }
  }

  .footer-bottom{
    width: 100%;
    background-color: #040e17;
  }
  .partner-list{
    line-height: 4rem;
    margin-bottom: 20px;
    img{
      display: inline-block;
      margin-right: 20px;
      width: 50%;
    }
  }
  @media #{$tablet}{
    .partner-list{
      line-height: 0;
      img{
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
  .footer-bottom{
    .container{
      padding: 40px;
    }

    @media #{$tablet}{
      ul{
        li{
          float: right;
          margin-right: 20px;
        }
      }
      p{
        float: right;
      }
    }
    p{

      margin-right: 20px;
      margin-bottom: 4px;
      font-size: 12px;
      color: white;
      margin-top: 5px;
    }
    a{
      font-size: 12px;
      color: white;
    }
    img{
      width: 64px;
    }
    .partner-list {
      img {
        width: 160px;
        margin-right: 0;
      }
    }
    .logo{
      float: left;
      margin-right: 20px;
    }
    @media #{$tablet}{
      .logo{
        float: right;
        margin-right: 0px;
      }
      .logo-container{
        float: right;
      }
    }
  }
}
.social-media{
  color: #00BBFF;
  a{
    display: block;
    float: left;
    margin-right: 20px;
    width: 18px;
    height: 18px;
    img{
      width: 18px;
    }

    @media #{$tablet}{
      a{
        margin-left: 20px;
        margin-right: 0;
      }
    }
  }
}
.mobil-margin{
  margin: 40px 0 20px;
}
@media #{$tablet}{
  .mobil-margin{
    margin: 0;
  }
}
.social-media{
  float: left;
}
@media #{$tablet}{
  .social-media{
    float: right;
  }
}
