main {
  padding: 60px 0 40px;
}
.container {
  @extend %clear;
  margin: auto;
  padding: 10px 10px;
  max-width: 1260px;
  box-sizing: border-box;
  // padding: 40px;
}

%clear {
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.content {
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}
.pull-center{
  text-align: center;
}
.row {
  @extend %clear;
  margin: 0 0px;

}

.col {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.language-selector {
  display: none;
}
.w60px{
  width: 60px;
}
@media #{$tablet} {
  main {
    padding-top: 140px;
  }
  .language-selector {
    display: block;
  }
}
@media #{$tablet} {


    main {
      padding-top: 140px;
    }
    .container {
      padding: 20px 20px;
    }

    .row {
      margin: 0 -10px;
    }

    .col {
      padding: 0px 10px;
    }

    .plusm {
      margin: 0 40px;
    }

    .w33_3 {
      width: 50%;
    }
    .w29_5 {
      width: 29.5%;
    }
    .w70_5 {
      width: 70.5%;
    }
    @for $i from 1 through 100 {
      .w#{$i} {
        width: $i * 1%;
      }
      /*
      .h#{$i} {
        height: $i * 1%;
      }

      .h#{$i * 10}px {
        height: $i * 10px;
      }
      */
      .w#{$i * 10}px {
        width: $i * 10px;
      }
    }


    .dblp {
      padding-left: 60px !important;
    }


  .w33_3 {
    width: 50%;
  }
}
@media #{$desktop} {
  main {
    padding-top: 100px;
  }
  .container {
    padding: 30px 40px;
  }
  .container-slim {
    padding: 30px 70px;
  }

  .row {
    margin: 0 -20px;
  }
.tb{
  .col {
    padding: 0px 20px;
  }
  .w33_3 {
    width: 33.333%;
  }
}
  .plusm {
    margin: 0 40px;
  }

  .w33_3 {
    width: 33.333%;
  }
  .w29_5 {
    width: 29.5%;
  }
  .w70_5 {
    width: 70.5%;
  }
  @for $i from 1 through 100 {
    .w#{$i} {
      width: $i * 1%;
    }

    .h#{$i} {
      height: $i * 1%;
    }

    .h#{$i * 10}px {
      height: $i * 10px;
    }

    .w#{$i * 10}px {
      width: $i * 10px;
    }
  }


  .dblp {
    padding-left: 60px !important;
  }
}
@media #{$mobile} {
  .main{

  }
}
