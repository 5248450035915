.button,
button,
input[type='submit'] {
  background-color: $brand-color-darker;
  box-shadow: 0px 1px 2px rgba(0 ,0 ,0 ,.5);
  text-decoration: none;
  border: 0;
  color: $text-white;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
  display: inline-block;
  height: $unit;
  padding: 0 $unit;
  line-height: $unit;
  text-align: center;
  text-transform: uppercase;
  &.unit {
    width: $unit;
    padding: 0;
  }
  &.warning {
    background-color: $brand-color-red;
  }
}

.button--alter {
  background-color: $brand-color;
}

.button--small{
  text-transform: none;
}
.w100p{
  width: 100%;
  padding: 0;
  margin-top: 20px;
}

label {
    line-height: 30px;
    font-size: 14px;
    margin-top:0;
}

.button-group {
  margin-top: 20px;
  .button, input {
    float: left;
    display: block;
    margin-right: 20px;
  }
}

input[type='text'], textarea {
  background-color: $white;
  border: 0;
  border-bottom: 1px solid $border-color;
  padding: 0 20px;
  height: $unit;
  width: 100%;
  line-height: $unit;
  box-sizing: border-box;
}
